import { React, useState, useEffect } from 'react';
import {
  Table,
  Statistic,
  Button,
  TableCell,
  Input,
  Segment,
  Form,
  Dropdown,
  Header,
} from 'semantic-ui-react';
import { SearchPerson } from '../search/search-person';
import { Box } from 'theme-ui';
import { db } from '../../lib/firebase';
import { useForm, Controller } from 'react-hook-form';
import { NotesInput } from './notes-input';
import { DidntPay } from '../modal/didntPay';

export const Listing = () => {
  const [attendants, setAttendants] = useState([]);
  const [query, setQuery] = useState('');
  const [smallSize, setSmallSize] = useState(0);
  const [mediumSize, setMediumSize] = useState(0);
  const [largeSize, setLargeSize] = useState(0);
  const [xLargeSize, setXLargeSize] = useState(0);
  const [xxLargeSize, setXXLargeSize] = useState(0);
  const [whoDidntPay, setwhoDidntPay] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [sizeOfShirt, setSizeOfShirt] = useState('');
  const [dayOfArrival, setDayOfArrival] = useState('');
  const [infoSubmitted, setInfoSubmitted] = useState(false);
  const [idOfSubmission, setIdOfSubmission] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      whereTheyFrom: '',
    },
  });

  const onSubmit = (data) => {
    setFirstName(data.firstName);
    const theAttendee = {
      firstName: data.firstName,
      lastName: data.lastName,
      sizeOfShirt: sizeOfShirt,
      phoneNumber: data.phoneNumber,
      dayOfArrival: dayOfArrival,
      whereTheyFrom: data.whereTheyFrom,
      checkedIn: false,
      feePaid: false,
    };
    db.collection('camp-attendees')
      .add(theAttendee)
      .then(function (docRef) {
        setIdOfSubmission(docRef.id);
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
    setInfoSubmitted(true);
  };
  const setPaid = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        feePaid: true,
      });
    } catch {
      return <Box>Couldn't add paid to person. Reload the page</Box>;
    }
  };

  const setNotPaid = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        feePaid: false,
      });
    } catch {
      return <Box>Couldn't add paid to person. Reload the page</Box>;
    }
  };

  const setCheckIn = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        checkedIn: true,
      });
    } catch {
      return <Box>Couldn't check-in the person. Reload the page</Box>;
    }
  };

  const setNotCheckIn = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        checkedIn: false,
      });
    } catch {
      return <Box>Couldn't check-in the person. Reload the page</Box>;
    }
  };

  useEffect(() => {
    const unsubscribe = db
      .collection('camp-attendees')
      .orderBy('lastName', 'asc')
      .onSnapshot((snapshot) => {
        const res = [];

        snapshot.forEach((doc) => {
          res.push({
            docRef: doc.id,
            ...doc.data(),
          });
        });

        setAttendants(res);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setSmallSize(0);
    setMediumSize(0);
    setLargeSize(0);
    setXLargeSize(0);
    setXXLargeSize(0);

    attendants.forEach((item) => {
      if (item.sizeOfShirt === 'Small') {
        setSmallSize((smallSize) => smallSize + 1);
      }
      if (item.sizeOfShirt === 'Medium') {
        setMediumSize((mediumSize) => mediumSize + 1);
      }
      if (item.sizeOfShirt === 'Large') {
        setLargeSize((largeSize) => largeSize + 1);
      }
      if (item.sizeOfShirt === 'X-Large') {
        setXLargeSize((xLargeSize) => xLargeSize + 1);
      }
      if (item.sizeOfShirt === 'XX-Large') {
        setXXLargeSize((xxLargeSize) => xxLargeSize + 1);
      }
    });
  }, [attendants]);

  const hello = () => {
    const headers = [
      'docRef',
      'lastName',
      'firstName',
      'dayOfArrival',
      'sizeOfShirt',
      'whereTheyFrom',
      'phoneNumber',
      'checkedIn',
      'feePaid',
      'notes',
    ];

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `${headers.join(',')}\r\n`;

    const wrapInQuotes = (data) => `"${data}"`;

    attendants.forEach((attendee) => {
      const rowData = [
        attendee.docRef,
        attendee.lastName,
        attendee.firstName,
        attendee.dayOfArrival,
        attendee.sizeOfShirt,
        attendee.whereTheyFrom,
        attendee.phoneNumber,
        attendee.checkedIn,
        attendee.feePaid,
      ];

      const row = rowData.map((x) => wrapInQuotes(x)).join(',');

      csvContent += `${row}\r\n`;
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'PNWCampAttendees.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Statistic.Group>
          <Statistic color='green' size='large'>
            <Statistic.Value>{attendants.length}</Statistic.Value>
            <Statistic.Label>Attendants</Statistic.Label>
          </Statistic>
          <Statistic color='grey' size='large'>
            <Statistic.Value>{smallSize}</Statistic.Value>
            <Statistic.Label>Small shirt</Statistic.Label>
          </Statistic>
          <Statistic color='grey' size='large'>
            <Statistic.Value>{mediumSize}</Statistic.Value>
            <Statistic.Label>Medium shirt</Statistic.Label>
          </Statistic>
          <Statistic color='grey' size='large'>
            <Statistic.Value>{largeSize}</Statistic.Value>
            <Statistic.Label>Large shirt</Statistic.Label>
          </Statistic>
          <Statistic color='grey' size='large'>
            <Statistic.Value>{xLargeSize}</Statistic.Value>
            <Statistic.Label>X-Large shirt</Statistic.Label>
          </Statistic>
          <Statistic color='grey' size='large'>
            <Statistic.Value>{xxLargeSize}</Statistic.Value>
            <Statistic.Label>XX-large shirt</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => hello()}
          size='huge'
          style={{ marginTop: '27px' }}
        >
          Download all attendees
        </Button>
      </Box>
      <Segment basic style={{display: 'flex', justifyContent: 'center'}}>
        <DidntPay attendants={attendants} setPaid={setPaid}/>
      </Segment>
      <Box>
        <Box sx={{ mt: '7px', mb: '10px' }}>
          <SearchPerson query={query} setQuery={setQuery} />
        </Box>
      </Box>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Last name</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>
            <Table.HeaderCell>Arrival day</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Phone number</Table.HeaderCell>
            <Table.HeaderCell>Shirt size</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {attendants
            .filter(({ lastName }) =>
              lastName.toLowerCase().includes(query.toLowerCase())
            )
            .map((attendee) => (
              <Table.Row key={attendee.docRef}>
                <Table.Cell>{attendee.lastName}</Table.Cell>
                <Table.Cell>{attendee.firstName}</Table.Cell>
                <Table.Cell>{attendee.dayOfArrival}</Table.Cell>
                <Table.Cell>{attendee.whereTheyFrom}</Table.Cell>
                <Table.Cell>{attendee.phoneNumber}</Table.Cell>
                <Table.Cell>{attendee.sizeOfShirt}</Table.Cell>
                <Table.Cell textAlign='center' width='2' singleLine>
                  {attendee.checkedIn ? (
                    <Button onClick={() => setNotCheckIn(attendee.docRef)}>
                      Checked In
                    </Button>
                  ) : (
                    <Button
                      color='green'
                      onClick={() => setCheckIn(attendee.docRef)}
                    >
                      Check in
                    </Button>
                  )}
                  {attendee.feePaid ? (
                    <Button onClick={() => setNotPaid(attendee.docRef)}>
                      Paid
                    </Button>
                  ) : (
                    <Button
                      color='orange'
                      onClick={() => setPaid(attendee.docRef)}
                    >
                      Pay
                    </Button>
                  )}
                </Table.Cell>
                <TableCell>
                  <NotesInput attendee={attendee} />
                </TableCell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Segment basic>
        <Box sx={{ padding: '20px' }}>
          <Box sx={{ paddingBottom: '1px', paddingTop: '20px' }}>
            <Form onSubmit={handleSubmit(onSubmit)} size='huge'>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z ]{1,50}$/,
                    message:
                      'First name required! Ensure there are no numbers or special characters.',
                  },
                }}
                render={({ field }) => (
                  <Form.Field>
                    <Header style={{ fontSize: '24px' }}>First name</Header>
                    <Input {...field} placeholder='Enter first name' />
                  </Form.Field>
                )}
              />
              {errors.firstName && (
                <Segment color='red' basic>
                  {errors.firstName.message}
                </Segment>
              )}
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z ]{1,50}$/,
                    message:
                      'Last name required! Ensure there are no numbers or special characters.',
                  },
                }}
                render={({ field }) => (
                  <Form.Field>
                    <Header style={{ fontSize: '24px' }}>Last name</Header>
                    <Input {...field} placeholder='Enter last name' />
                  </Form.Field>
                )}
              />
              {errors.lastName && (
                <Segment color='red' basic>
                  {errors.lastName.message}
                </Segment>
              )}
              <Controller
                name='phoneNumber'
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{10}$/,
                    message:
                      'Enter 10 digit phone number. Please type in only the numbers, with out the +1. Number from a different country? Put in your friends number!',
                  },
                }}
                render={({ field }) => (
                  <Form.Field>
                    <Header style={{ fontSize: '24px' }}>
                      Phone number (no spaces, dashes, or +1)
                    </Header>
                    <Input {...field} placeholder='Enter phone number' />
                  </Form.Field>
                )}
              />
              {errors.phoneNumber && (
                <Segment color='red' basic>
                  {errors.phoneNumber.message}
                </Segment>
              )}
              <Form.Field>
                <Header style={{ fontSize: '24px' }}>Size of shirt</Header>
                <Dropdown
                  placeholder='Select size'
                  value={sizeOfShirt}
                  onChange={(_, { value }) => setSizeOfShirt(value)}
                  fluid
                  selection
                  options={[
                    { key: 'Small', value: 'Small', text: 'Small' },
                    { key: 'Medium', value: 'Medium', text: 'Medium' },
                    { key: 'Large', value: 'Large', text: 'Large' },
                    { key: 'X-Large', value: 'X-Large', text: 'X-Large' },
                    { key: 'XX-Large', value: 'XX-Large', text: 'XX-Large' },
                    { key: 'Loser Didn\'t Register', value: 'Loser Didn\'t Register', text: 'Loser Didn\'t Register' },
                  ]}
                />
              </Form.Field>
              <Form.Field>
                <Header style={{ fontSize: '24px' }}>Arrival day</Header>
                <Dropdown
                  placeholder='Select day'
                  value={dayOfArrival}
                  onChange={(_, { value }) => setDayOfArrival(value)}
                  fluid
                  selection
                  options={[
                    { key: 'Thursday', value: 'Thursday', text: 'Thursday' },
                    { key: 'Friday', value: 'Friday', text: 'Friday' },
                    { key: 'Saturday', value: 'Saturday', text: 'Saturday' },
                  ]}
                />
              </Form.Field>
              <Controller
                name='whereTheyFrom'
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z ]{1,50}$/,
                    message:
                      'State required! Ensure there are no numbers or special characters.',
                  },
                }}
                render={({ field }) => (
                  <Form.Field>
                    <Header style={{ fontSize: '24px' }}>
                      What city are you from?
                    </Header>
                    <Input {...field} placeholder='Enter full name' />
                  </Form.Field>
                )}
              />
              {errors.whereTheyFrom && (
                <Segment color='red' basic>
                  {errors.whereTheyFrom.message}
                </Segment>
              )}
              <Button color='green' type='submit'>
                Submit
              </Button>
            </Form>
          </Box>
        </Box>
      </Segment>
    </Box>
  );
};
