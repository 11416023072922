import React from 'react';
import { Segment } from 'semantic-ui-react';

var d = new Date();
var fullYear = d.getFullYear();

export const Footer = () => {
  return (
    <Segment textAlign='center' inverted color='grey' attached='bottom' style={{fontSize: '15px'}}>
      GSBC Youth | V.S. {fullYear}
    </Segment>
  );
};
