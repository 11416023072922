import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { Box } from 'theme-ui';

import PNWPic from '../../pics/PNWPic.png';

export const Home = () => {
  return (
    <Box sx={{ display: 'block', textAlign: 'center' }}>
      <Box sx={{marginTop: '30px'}}>
        <Image src={PNWPic} size='large' centered />
      </Box>
      <Box sx={{ display: 'block', marginTop: '10px' }}>
        <Button href='/information' size='huge' style={{width: '250px'}} color='green'>
          Register for fall camp
        </Button>
      </Box>
      <Box sx={{ display: 'block', marginTop: '18px'}}>
        <Button href='/update' size='huge' style={{width: '250px'}}>
          Update information
        </Button>
      </Box>
      <Box sx={{ display: 'block', marginTop: '18px'}}>
        <Button href='/media' size='huge' style={{width: '250px'}}>
          Media
        </Button>
      </Box>
      <Box sx={{ display: 'block', marginTop: '18px'}}>
        <Button href='/admin' size='huge' style={{width: '250px'}}>
          Admin
        </Button>
      </Box>
      <Box sx={{ display: 'block', marginTop: '15px', fontSize:'18px', lineHeight:'22px'}}>
        <a href='mailto:gyouth5116@gmail.com'>
          Questions? Email us at: <br/>gyouth5116@gmail.com
        </a>
      </Box>
    </Box>
  );
};
