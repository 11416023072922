import React, { useState } from 'react';
import { db } from '../../lib/firebase';
import { Button, Input, Segment } from 'semantic-ui-react';
import { Box } from 'theme-ui';

export const NotesInput = ({ attendee }) => {
  const [notes, setNotes] = useState(attendee.notes);

  const editNotesBox = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        notes: notes,
      });
    } catch {
      return <Box>Couldn't check-in the person. Reload the page</Box>;
    }
  };

  return (
    <Segment basic textAlign="center">
      <Input
        placeholder='Add notes'
        value={notes}
        onChange={({ target: { value } }) => setNotes(value)}
      />
      <Button onClick={() => editNotesBox(attendee.docRef)}>Save</Button>
    </Segment>
  );
};
