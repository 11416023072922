import React from 'react';
import { Box } from 'theme-ui';

import { InfoForm } from '../forms/personal-details';

export const Registration = () => {
  return (
    <Box>
      <InfoForm />
    </Box>
  );
};
