import React, { useState } from 'react';
import {
  Input,
  Form,
  Header,
  Dropdown,
  Button,
  Dimmer,
  Loader,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { Box, Flex } from 'theme-ui';
import { useForm, Controller } from 'react-hook-form';

import { db } from '../../lib/firebase';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const InfoForm = () => {
  const [firstName, setFirstName] = useState('');
  const [sizeOfShirt, setSizeOfShirt] = useState('');
  const [dayOfArrival, setDayOfArrival] = useState('');
  const [infoSubmitted, setInfoSubmitted] = useState(false);
  const [idOfSubmission, setIdOfSubmission] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      whereTheyFrom: '',
    },
  });

  const onSubmit = (data) => {
    setFirstName(data.firstName);
    const theAttendee = {
      firstName: data.firstName,
      lastName: data.lastName,
      sizeOfShirt: sizeOfShirt,
      phoneNumber: data.phoneNumber,
      dayOfArrival: dayOfArrival,
      whereTheyFrom: data.whereTheyFrom,
      checkedIn: false,
      feePaid: false,
    };
    db.collection('camp-attendees')
      .add(theAttendee)
      .then(function (docRef) {
        setIdOfSubmission(docRef.id);
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
    setInfoSubmitted(true);
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/information'
          />
        </Box>
        <Box>
          <Header textAlign='center' size='huge'>
            Registration
          </Header>
        </Box>
      </Flex>
      <Box sx={{ paddingBottom: '1px', paddingTop: '20px' }}>
        {!infoSubmitted ? (
          <Form onSubmit={handleSubmit(onSubmit)} size='huge'>
            <Controller
              name='firstName'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z ]{1,50}$/,
                  message:
                    'First name required! Ensure there are no numbers or special characters.',
                },
              }}
              render={({ field }) => (
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>First name</Header>
                  <Input {...field} placeholder='Enter first name' />
                </Form.Field>
              )}
            />
            {errors.firstName && (
              <Segment color='red' basic>
                {errors.firstName.message}
              </Segment>
            )}
            <Controller
              name='lastName'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z ]{1,50}$/,
                  message:
                    'Last name required! Ensure there are no numbers or special characters.',
                },
              }}
              render={({ field }) => (
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>Last name</Header>
                  <Input {...field} placeholder='Enter last name' />
                </Form.Field>
              )}
            />
            {errors.lastName && (
              <Segment color='red' basic>
                {errors.lastName.message}
              </Segment>
            )}
            <Controller
              name='phoneNumber'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^\d{10}$/,
                  message:
                    'Enter 10 digit phone number. Please type in only the numbers, with out the +1. Number from a different country? Put in your friends number!',
                },
              }}
              render={({ field }) => (
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>
                    Phone number (no spaces, dashes, or +1)
                  </Header>
                  <Input {...field} placeholder='Enter phone number' />
                </Form.Field>
              )}
            />
            {errors.phoneNumber && (
              <Segment color='red' basic>
                {errors.phoneNumber.message}
              </Segment>
            )}
            {/* <Form.Field>
              <Header style={{ fontSize: '24px' }}>Size of shirt</Header>
              <Dropdown
                placeholder='Select size'
                value={sizeOfShirt}
                onChange={(_, { value }) => setSizeOfShirt(value)}
                fluid
                selection
                options={[
                  { key: 'Small', value: 'Small', text: 'Small' },
                  { key: 'Medium', value: 'Medium', text: 'Medium' },
                  { key: 'Large', value: 'Large', text: 'Large' },
                  { key: 'X-Large', value: 'X-Large', text: 'X-Large' },
                  { key: 'XX-Large', value: 'XX-Large', text: 'XX-Large' },
                ]}
              />
            </Form.Field> */}
            <Form.Field>
              <Header style={{ fontSize: '24px' }}>Arrival day</Header>
              <Dropdown
                placeholder='Select day'
                value={dayOfArrival}
                onChange={(_, { value }) => setDayOfArrival(value)}
                fluid
                selection
                options={[
                  { key: 'Thursday', value: 'Thursday', text: 'Thursday' },
                  { key: 'Friday', value: 'Friday', text: 'Friday' },
                  { key: 'Saturday', value: 'Saturday', text: 'Saturday' },
                ]}
              />
            </Form.Field>
            <Controller
              name='whereTheyFrom'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z ]{1,50}$/,
                  message:
                    'State required! Ensure there are no numbers or special characters.',
                },
              }}
              render={({ field }) => (
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>
                    What city are you from?
                  </Header>
                  <Input {...field} placeholder='Enter full name' />
                </Form.Field>
              )}
            />
            {errors.whereTheyFrom && (
              <Segment color='red' basic>
                {errors.whereTheyFrom.message}
              </Segment>
            )}
            <Button color='green' type='submit'>
              Submit
            </Button>
          </Form>
        ) : (
          <Confirmation theId={idOfSubmission} firstName={firstName} />
        )}
      </Box>
    </Box>
  );
};

export const Confirmation = ({ theId, firstName }) => {
  return (
    <Segment>
      {(firstName !== '' || firstName === undefined) &&
      (theId !== '' || theId === null) ? (
        <Box sx={{ fontSize: '20px', textAlign: 'center' }}>
          <Header>Thanks, {firstName}, for registering!</Header>
          <Box>
            <Box sx={{ mt: '10px' }}>
              <Box sx={{ paddingBottom: '10px' }}>This is your ID:</Box>
              <Box sx={{ pl: 1, pr: 2, fontWeight: 'bold' }} as='code'>
                {theId}
              </Box>
              <Button compact basic icon onClick={() => copyToClipboard(theId)}>
                <Icon name='copy' />
              </Button>
            </Box>
            <Box sx={{ mt: '10px', mb: '10px', fontSize: '16px' }}>
              Please copy or screenshot this ID. You could use this to update
              your registration later!
            </Box>
            <Button href='/' basic>
              Go home
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ fontSize: '20px', textAlign: 'center' }}>
          <Segment basic style={{ marginTop: '10px' }}>
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          </Segment>
          <Segment basic>
            <Button href='/registration'>
              Nothing happening? Try registering again!
            </Button>
            <Box>
              <Box sx={{ fontSize: '20px', mt: '15px' }}>
                Or send us an email:
              </Box>
              <Box
                sx={{
                  display: 'block',
                  marginTop: '6px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                <a href='mailto:gyouth5116@gmail.com'>gyouth5116@gmail.com</a>
              </Box>
            </Box>
          </Segment>
        </Box>
      )}
    </Segment>
  );
};
