import React, { useState, useEffect } from 'react';
import {
  Input,
  Header,
  Dimmer,
  Loader,
  Button,
  Segment,
  Form,
  Divider,
  Dropdown,
} from 'semantic-ui-react';
import { Box, Flex } from 'theme-ui';

import { db } from '../../lib/firebase';
import { useForm, Controller } from 'react-hook-form';
import { UpdatedModal } from './modals';

export const Update = () => {
  //put in the id to search for the entry
  //check it through regex
  //submit and let the code access firebase
  //load up all the information

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [theSizeOfShirt, setTheSizeOfShirt] = useState('');
  const [theArrivalDay, setTheArrivalDay] = useState('');
  const [theLocation, setTheLocation] = useState('');
  const [theData, setTheData] = useState('');
  const [submittedId, setSubmittedId] = useState('');
  const [entryExists, setEntryExists] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  // const [searchChecked, setSearchChecked] = useState(false);
  // console.log(firstName);
  // console.log(lastName);
  // console.log(phoneNumber);
  // console.log(theData);
  // console.log(entryExists);
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      submittedId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      whereTheyFrom: '',
    },
  });

  // useEffect(() => {
  //   if (submittedId === '') return;
  //   if (submittedId.length !== 20) return;

  //   if (submittedId === '') {
  //     setEntryExists(false);
  //   }

  //   entryCheck(submittedId);
  // }, [submittedId]);

  // useEffect(() => {
  //   if (submittedId === '') return;
  //   if (submittedId.length !== 20) return;
  //   if (entryExists) {
  //     getData(submittedId);
  //   }
  // }, [entryExists, submittedId]);

  //entryCheck can't be updated, because the submittedId is empty
  // useEffect(() => {
  //   if (theData === '') return;
  //   setCurrentValues(theData);
  //   setSearchChecked(true);
  // }, [theData]);

  useEffect(() => {
    if (theData === '') return;
    setCurrentValues(theData);
    setEntryExists(true);
  }, [theData]);

  const getData = async (id) => {
    try {
      await db
        .collection('camp-attendees')
        .doc(id)
        .get()
        .then((snapshot) => setTheData(snapshot.data()));
    } catch {
      return console.log("There's an error in the getData() function");
    }
  };

  const setCurrentValues = (theData) => {
    if (!theData) return;
    setFirstName(theData.firstName);
    setLastName(theData.lastName);
    setPhoneNumber(theData.phoneNumber);
    setTheSizeOfShirt(theData.sizeOfShirt);
    setTheArrivalDay(theData.dayOfArrival);
    setTheLocation(theData.whereTheyFrom);
  };

  const handleUpdate = async (id) => {
    try {
      await db.collection('camp-attendees').doc(id).update({
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        sizeOfShirt: theSizeOfShirt,
        dayOfArrival: theArrivalDay,
        whereTheyFrom: theLocation,
      });
      entryCheck(id);
    } catch {
      return console.log("Couldn't update the entry");
    }
  };

  const handleDelete = async () => {
    try {
      await db.collection('camp-attendees').doc(submittedId).delete();
      setIsDeleted(true);
      setSubmittedId('');
    } catch {
      return console.log('There was a problem with deleting the registration');;
    }
  };

  const entryCheck = async (id) => {
    try {
      const ref = db.collection('camp-attendees').doc(id);
      ref.get().then((snapshot) => {
        if (snapshot.exists) {
          setEntryExists(true);
          setIsUpdated(true);
        }
      });
    } catch {
      return console.log('Something happened in entry check. Please check');
    }
  };

  const onSubmit = (data) => {
    getData(data.submissionId);
    setSubmittedId(data.submissionId);
  };

  return (
    <Box sx={{ textAlign: 'left', margin: '20px' }}>
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/'
          />
        </Box>
        <Box>
          <Header textAlign='center' size='huge'>
            Update information
          </Header>
        </Box>
      </Flex>
      {!isUpdated && !isDeleted ? (
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} size='huge'>
            <Controller
              name='submissionId'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[\S\d]{20}$/,
                  message:
                    '20 digit code required! Ensure there are no spaces.',
                },
              }}
              render={({ field }) => (
                <Form.Field>
                  <Header style={{ fontSize: '24px', marginTop: '20px' }}>
                    Enter your 20 character id
                  </Header>
                  <Input {...field} placeholder='Enter id' />
                </Form.Field>
              )}
            />
            {errors.submissionId && (
              <Segment color='red' basic>
                {errors.submissionId.message}
              </Segment>
            )}
            <Button color='green' type='submit'>
              Submit
            </Button>
          </Form>
          {entryExists !== '' && theData !== '' && theData !== undefined ? (
            <Segment basic>
              <Box sx={{ textAlign: 'center', fontSize: '16px' }}>
                <Header size='huge' style={{ marginBottom: '10px' }}>
                  Update
                </Header>
              </Box>
              <Form onSubmit={() => handleUpdate(submittedId)} size='huge'>
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>First Name</Header>
                  <Input
                    placeholder='First name'
                    value={firstName}
                    name='firstName'
                    onChange={(_, { value }) => setFirstName(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>Last Name</Header>
                  <Input
                    placeholder='Last name'
                    value={lastName}
                    name='lastName'
                    onChange={(_, { value }) => setLastName(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>
                    Phone Number (no spaces or dashes)
                  </Header>
                  <Input
                    placeholder='Enter phone number'
                    value={phoneNumber}
                    name='phoneNumber'
                    onChange={(_, { value }) => setPhoneNumber(value)}
                  />
                </Form.Field>
                {/* <Form.Field>
                  <Header style={{ fontSize: '24px' }}>Size of Shirt</Header>
                  <Dropdown
                    placeholder='Select size'
                    value={theSizeOfShirt}
                    onChange={(_, { value }) => setTheSizeOfShirt(value)}
                    fluid
                    selection
                    options={[
                      { key: 'Small', value: 'Small', text: 'Small' },
                      {
                        key: 'Medium',
                        value: 'Medium',
                        text: 'Medium',
                      },
                      { key: 'Large', value: 'Large', text: 'Large' },
                      {
                        key: 'X-Large',
                        value: 'X-Large',
                        text: 'X-Large',
                      },
                      {
                        key: 'XX-Large',
                        value: 'XX-Large',
                        text: 'XX-Large',
                      },
                    ]}
                  />
                </Form.Field> */}
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>Arrival Day</Header>
                  <Dropdown
                    placeholder='Select day'
                    value={theArrivalDay}
                    onChange={(_, { value }) => setTheArrivalDay(value)}
                    fluid
                    selection
                    options={[
                      {
                        key: 'Thursday',
                        value: 'Thursday',
                        text: 'Thursday',
                      },
                      {
                        key: 'Friday',
                        value: 'Friday',
                        text: 'Friday',
                      },
                      {
                        key: 'Saturday',
                        value: 'Saturday',
                        text: 'Saturday',
                      },
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <Header style={{ fontSize: '24px' }}>
                    What church do you go to?
                  </Header>
                  <Input
                    placeholder='Enter church location'
                    value={theLocation}
                    name='phoneNumber'
                    onChange={(_, { value }) => setTheLocation(value)}
                  />
                </Form.Field>

                <Button color='green' type='submit' size='large'>
                  Update
                </Button>
              </Form>
              <Divider horizontal>Or</Divider>

              <Box sx={{ textAlign: 'center', fontSize: '16px' }}>
                <Header size='huge'>Delete</Header>
                <Button negative onClick={() => handleDelete()} size='large'>
                  Delete registration
                </Button>
              </Box>
            </Segment>
          ) : (
            <Box sx={{ fontSize: '20px', textAlign: 'center' }}>
              <Segment basic style={{ marginTop: '10px' }}>
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              </Segment>
              <Segment basic>
                <Box>
                  <Box sx={{ fontSize: '20px', mt: '15px' }}>
                    Problems? Just send us an email:
                  </Box>
                  <Box
                    sx={{
                      display: 'block',
                      marginTop: '6px',
                      fontSize: '18px',
                      lineHeight: '22px',
                    }}
                  >
                    <a href='mailto:gyouth5116@gmail.com'>
                      gyouth5116@gmail.com
                    </a>
                  </Box>
                </Box>
              </Segment>
            </Box>
          )}
          {/* <Segment basic>
          {isDeleted ? (
            <Deleted />
          ) : (
            <Box>
              {isUpdated ? (
                <Segment>
                  <Box sx={{ fontSize: '20px', textAlign: 'center' }}>
                    You have successfully updated your registration!
                    <Box sx={{ mt: '13px' }}>
                      <Button href='/home' basic>
                        Go home
                      </Button>
                    </Box>
                  </Box>
                </Segment>
              ) : (
                <Box>
                  {entryExists ? (
                    <Box>
                      <Box sx={{ textAlign: 'center', fontSize: '16px' }}>
                        <Header size='huge' style={{ marginBottom: '10px' }}>
                          Update
                        </Header>
                      </Box>
                      <Form
                        onSubmit={() => handleUpdate(submittedId)}
                        size='huge'
                      >
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            First Name
                          </Header>
                          <Input
                            placeholder='First name'
                            value={firstName}
                            name='firstName'
                            onChange={(_, { value }) => setFirstName(value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            Full Name
                          </Header>
                          <Input
                            placeholder='Last name'
                            value={lastName}
                            name='lastName'
                            onChange={(_, { value }) => setLastName(value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            Phone Number (no spaces or dashes)
                          </Header>
                          <Input
                            placeholder='Enter phone number'
                            value={phoneNumber}
                            name='phoneNumber'
                            onChange={(_, { value }) => setPhoneNumber(value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            Size of Shirt
                          </Header>
                          <Dropdown
                            placeholder='Select size'
                            value={theSizeOfShirt}
                            onChange={(_, { value }) =>
                              setTheSizeOfShirt(value)
                            }
                            fluid
                            selection
                            options={[
                              { key: 'Small', value: 'Small', text: 'Small' },
                              {
                                key: 'Medium',
                                value: 'Medium',
                                text: 'Medium',
                              },
                              { key: 'Large', value: 'Large', text: 'Large' },
                              {
                                key: 'X-Large',
                                value: 'X-Large',
                                text: 'X-Large',
                              },
                              {
                                key: 'XX-Large',
                                value: 'XX-Large',
                                text: 'XX-Large',
                              },
                            ]}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            Arrival Day
                          </Header>
                          <Dropdown
                            placeholder='Select day'
                            value={theArrivalDay}
                            onChange={(_, { value }) => setTheArrivalDay(value)}
                            fluid
                            selection
                            options={[
                              {
                                key: 'Thursday',
                                value: 'Thursday',
                                text: 'Thursday',
                              },
                              {
                                key: 'Friday',
                                value: 'Friday',
                                text: 'Friday',
                              },
                              {
                                key: 'Saturday',
                                value: 'Saturday',
                                text: 'Saturday',
                              },
                            ]}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Header style={{ fontSize: '24px' }}>
                            What church do you go to?
                          </Header>
                          <Input
                            placeholder='Enter church location'
                            value={theLocation}
                            name='phoneNumber'
                            onChange={(_, { value }) => setTheLocation(value)}
                          />
                        </Form.Field>

                        <Button color='green' type='submit' size='large'>
                          Update
                        </Button>
                      </Form>

                      <Divider horizontal>Or</Divider>

                      <Box sx={{ textAlign: 'center', fontSize: '16px' }}>
                        <Header size='huge'>Delete</Header>
                        <Button negative onClick={handleDelete} size='large'>
                          Delete registration
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    // <Box>
                    //   {searchChecked && !entryExists ? (
                    //     <Box sx={{ fontSize: '16px' }}>
                    //       Sorry bro, not a valid id. Make sure there are no
                    //       spaces or more than 20 characters
                    //     </Box>
                    //   ) : (
                    //     <Box>
                    //       <Dimmer active inverted>
                    //         <Loader />
                    //       </Dimmer>
                    //     </Box>
                    //   )}
                    // </Box>
                    <Box sx={{ fontSize: '20px', textAlign: 'center' }}>
                      <Segment basic style={{ marginTop: '10px' }}>
                        <Dimmer active inverted>
                          <Loader />
                        </Dimmer>
                      </Segment>
                      <Segment basic>
                        <Button href='/registration'>
                          Nothing happening? Try registering again!
                        </Button>
                        <Box>
                          <Box sx={{ fontSize: '20px', mt: '15px' }}>
                            Or send us an email:
                          </Box>
                          <Box
                            sx={{
                              display: 'block',
                              marginTop: '6px',
                              fontSize: '18px',
                              lineHeight: '22px',
                            }}
                          >
                            <a href='mailto:gyouth5116@gmail.com'>
                              gyouth5116@gmail.com
                            </a>
                          </Box>
                        </Box>
                      </Segment>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Segment> */}
        </Box>
      ) : (
        <ConfirmUpdate />
      )}

      {isUpdated ? <UpdatedModal /> : null}
    </Box>
  );
};

export const ConfirmUpdate = () => {
  return (
    <Segment style={{ fontSize: '20px', textAlign: 'center' }}>
      Registration has been updated!
      <Box sx={{ mt: '20px' }}>
        <Button href='/' basic>
          Go home
        </Button>
      </Box>
    </Segment>
  );
};
