import React from 'react';
import {
  Modal,
  Button,
  Statistic,
  Segment,
  Table,
  TableCell,
} from 'semantic-ui-react';
import { Box } from 'theme-ui';

export const DidntPay = ({ attendants, setPaid }) => {
  const filtered = attendants.filter((attendee) => attendee.feePaid === false);
  return (
    <Modal
      trigger={
        <Box>
          <Button color='green' size='large'>
            Show Who Didn't Pay
          </Button>
        </Box>
      }
      header="Who Didn't Pay"
      content={
        <Segment basic>
          <Segment basic style={{ display: 'flex', justifyContent: 'center' }}>
            <Statistic.Group>
              <Statistic color='green' size='large'>
                <Statistic.Value>{filtered.length}</Statistic.Value>
                <Statistic.Label>Unpaid</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
          {filtered.map((attendee) => (
            <Table celled>
              <Table.Row key={attendee.docRef}>
                <Table.Cell textAlign='center'>
                  {attendee.firstName} {attendee.lastName} -{' '}
                  {attendee.whereTheyFrom}
                </Table.Cell>
                <Table.Cell textAlign='center' width='2' singleLine>
                  <Button
                    color='orange'
                    onClick={() => setPaid(attendee.docRef)}
                  >
                    Pay
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table>
          ))}
        </Segment>
      }
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  );
};
