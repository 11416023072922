import React from 'react';
import { Box, Flex } from 'theme-ui';
import { Header, Button, Image, List, Segment } from 'semantic-ui-react';

import PersonPic from '../../pics/PersonImage.png';
import GoogleMapsPic from '../../pics/googlemaps2.png';
import AppleMapsPic from '../../pics/applemaps.png';

export const CampInfo = () => {
  return (
    <Box sx={{ marginTop: '20px' }}>
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/'
          />
        </Box>
        <Box>
          <Header textAlign='center' style={{ fontSize: '35px' }}>
            Information
          </Header>
        </Box>
      </Flex>

      <Box sx={{ textAlign: 'center', mt: '15px' }}>
        <Segment basic style={{ fontSize: '24px' }}>
          <Header>Dates:</Header>
          <Box sx={{ fontSize: '24px' }}>October 26th - 29th, 2023</Box>
        </Segment>

        <Segment basic style={{ fontSize: '24px' }}>
          <Header>Location:</Header>
          <Box
            sx={{
              fontSize: '24px',
              lineHeight: '35px',
            }}
          >
            3362 Thompson Rd.
            <br />
            Valley, WA 99181
            <br />
            <Box sx={{ textAlign: 'center', mt: '10px' }}>
              <Image
                src={GoogleMapsPic}
                size='tiny'
                href='https://goo.gl/maps/vLqBDFy5hwv6tjWf7'
                target='_blank'
              />
              <Image
                src={AppleMapsPic}
                size='tiny'
                href='https://maps.apple.com/?address=3362%20Thompson%20Rd,%20Valley,%20WA%20%2099181,%20United%20States&ll=48.194698,-117.791766&q=3362%20Thompson%20Rd&_ext=EiYpn2Egr1gYSEAxwpEzsBpzXcA5HTdGC38ZSEBBgrmP5T1yXcBQAw%3D%3D'
                target='_blank'
              />
            </Box>
          </Box>
        </Segment>
        {/* <Segment basic style={{ fontSize: '24px' }}>
          <Header>Theme:</Header>
          <Box sx={{ lineHeight: '30px' }}>
            No set theme yet
          </Box>
        </Segment> */}

        <Segment basic style={{ fontSize: '24px' }}>
          <Header>Guest speaker:</Header>
          <Box sx={{ fontSize: '24px' }}>Вячеслав Мадудяк</Box>
          <Box sx={{ mt: '30px' }}>
            <Image
              src={PersonPic}
              size='medium'
              centered
              style={{ boxShadow: '0 0 8px #808080', borderRadius: '10px' }}
            />
          </Box>
        </Segment>
        {/* <Divider hidden /> */}
        <Segment basic style={{ fontSize: '24px' }}>
          <Header>Cost:</Header>
          <Box>$140</Box>
          <Box sx={{mt: '10px', fontSize:'15px'}}>Non-refundable after October 10th</Box>
          {/* <Box sx={{ mt: '10px', fontSize: '15px' }}>
            Everyone will get a free sweat shirt that is not included in the price.
          </Box> */}
        </Segment>
        {/* <Divider hidden /> */}
        <Segment basic style={{ fontSize: '24px', marginTop: '10px' }}>
          <Header>Rules:</Header>
          <List as='ul'>
            <List.Item as='li'>
              Check in starts at <b>4pm</b> on Thursday.
              <br />
            </List.Item>
            <List.Item as='li'>
              You must check in when you arrive to camp.
            </List.Item>
            <List.Item as='li'>
              Cost of camp is to be paid <b>on or by the first day of camp (or when you arrive, if you come later)</b>. <br/> Стоимость лагеря оплачивается в первый день лагеря или до лагеря.
            </List.Item>
            <List.Item as='li'>
              Limited to 200 people.
            </List.Item>
          </List>
        </Segment>
        <Segment basic style={{ fontSize: '24px', marginTop: '10px' }}>
          <Header>Payment:</Header>
          <List as='ul'>
            <List.Item as='li'>
              CashApp: $GSBCYouth
              <br />
            </List.Item>
            <List.Item as='li'>
              Zelle: gyouth5116@gmail.com
            </List.Item>
            <List.Item as='li'>
              Pay with cash or check <br/>(make it payable to 'GSBC Youth').
            </List.Item>
          </List>
        </Segment>
      </Box>
    </Box>
  );
};
