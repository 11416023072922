import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { Header, Divider, Button, Loader, Dimmer } from 'semantic-ui-react';
import { db } from '../../lib/firebase';
import { CampInfo } from './camp-info';

export const InfoPage = () => {
  const [displayControls, setDisplayControls] = useState([]);
  const fetchDisplayControls = async () => {
    const snapshot = await db.collection('site-controls').get();

    const res = snapshot.docs.map((displayContol) => ({
      registration: displayContol,
      ...displayContol.data(),
    }));
    setDisplayControls(res);
  };

  useEffect(() => {
    fetchDisplayControls();
  }, []);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <CampInfo />
      <Divider hidden />
      {displayControls.length === 0 ? (
        <Box>
          <Dimmer>
            <Loader>Loading</Loader>
          </Dimmer>
        </Box>
      ) : (
        <Box>
          {displayControls[0].registration ? (
            <Box sx={{ textAlign: 'center', paddingBottom: '60px' }}>
              <Header style={{ fontSize: '35px' }}>Ready to register?</Header>
              <Button.Group size='huge'>
                <Button positive href='/registration'>
                  Yep
                </Button>
                <Button.Or />
                <Button negative href='/'>
                  Nah
                </Button>
              </Button.Group>
            </Box>
          ) : (
            <Box>
              <Header size='huge'>
                Registration is closed! Have any questions?
                <br />
                Just email:
              </Header>
              <Box
                sx={{
                  display: 'block',
                  marginTop: '15px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                <a href='mailto:gyouth5116@gmail.com'>gyouth5116@gmail.com</a>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
