import React from 'react';
import { Box, Flex } from 'theme-ui';
import { Route, useRouteMatch, Switch } from 'react-router';
import { Image, Header, Button, Message } from 'semantic-ui-react';

import TwentyTwentyCover from '../../pics/2020Cover.jpg';
import TwentyTwentyOneCover from '../../pics/2021Cover.jpg';
import TwentyTwentyOneThumb from '../../pics/2021Thumbnail.jpg';
import TwentyTwentyFunnyThumb from '../../pics/2021FunnyThumb.jpg';
import DriveLogo from '../../pics/GoogleDrive.png';

export const MediaPageLinks = () => {
  let { path } = useRouteMatch();
  //here we need to grab the proper links
  return (
    <Switch>
      <Route path={`${path}/2021`}>
        <Year2021 />
      </Route>
      <Route path={`${path}/2020`}>
        {/* <DisplayYear year={2020} /> */}
        <Year2020 />
      </Route>
      <Route exact path={path}>
        <YearSelection />
      </Route>
    </Switch>
  );
};

export const YearSelection = () => {
  return (
    <Box
      sx={{ display: 'block', justifyContent: 'center', textAlign: 'center', m: '20px' }}
    >
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/'
          />
        </Box>
        <Box>
          <Header textAlign='center' size='huge'>
            Media
          </Header>
        </Box>
      </Flex>
      <Box sx={{ display: 'block', textAlign: 'center' }}>
        <Box sx={{ marginTop: '30px' }}>
          <Button href='/media/2021' style={{ width: '140px' }} size='massive'>
            2021
          </Button>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Button href='/media/2020' style={{ width: '140px' }} size='massive'>
            2020
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const Year2021 = () => {
  return (
    <Box
      sx={{ display: 'block', justifyContent: 'center', textAlign: 'center', m: '20px' }}
    >
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/media'
          />
        </Box>
        <Box>
          <Header textAlign='center' size='huge'>
            2021
          </Header>
        </Box>
      </Flex>
      <Box sx={{ mt: '25px' }}>
        <Header size='large'>Photos:</Header>
        <Flex
          sx={{
            display: 'inline-block',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Message style={{ width: '305px' }} color='teal' size='large'>
            <Box>Get Google Photos to have a better viewing experience</Box>
          </Message>
        </Flex>
        <Box sx={{ mt: '30px' }}>
          <Image
            src={TwentyTwentyOneCover}
            size='medium'
            href='https://photos.app.goo.gl/npgC3hZxFhehSb8a6'
            target='_blank'
            centered
            rounded
            style={{ boxShadow: '0 0 8px #808080', borderRadius: '10px' }}
          />
        </Box>
        <Box sx={{ mt: '25px' }}>
          <Header size='large'>Slideshow for church:</Header>
          <Box sx={{ mt: '25px' }}>
            <Image
              src={TwentyTwentyOneThumb}
              size='medium'
              href='https://youtu.be/tgJlTxg4aPY'
              target='_blank'
              centered
              rounded
              style={{ boxShadow: '0 0 8px #808080', borderRadius: '10px' }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: '25px' }}>
          <Header size='large'>
            Slideshow for youth
            <br />
            (not for church):
          </Header>
          <Box sx={{ mt: '25px' }}>
            <Image
              src={TwentyTwentyFunnyThumb}
              size='medium'
              href='https://youtu.be/yAipfA5JNEA'
              target='_blank'
              centered
              rounded
              style={{ boxShadow: '0 0 8px #808080', borderRadius: '10px' }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: '25px' }}>
          <Button
            href='https://drive.google.com/drive/folders/1YaxP0TccBNjqH0ubdxqFsIrx5lEI03Bi?usp=sharing'
            target='_blank'
          >
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ mr: '8px' }}>
                <Image src={DriveLogo} size='mini' />
              </Box>
              Download full size images
            </Flex>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const Year2020 = () => {
  return (
    <Box
      sx={{ display: 'block', justifyContent: 'center', textAlign: 'center', m: '20px' }}
    >
      <Flex
        sx={{
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mr: '10px' }}>
          <Button
            basic
            color='black'
            size='medium'
            icon='arrow alternate circle left'
            href='/media'
          />
        </Box>
        <Box>
          <Header textAlign='center' size='huge'>
            2020
          </Header>
        </Box>
      </Flex>
      <Box sx={{ mt: '25px' }}>
        <Header size='large'>Photos:</Header>
        <Flex
          sx={{
            display: 'inline-block',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Message style={{ width: '305px' }} color='teal' size='large'>
            <Box>Get Google Photos to have a better viewing experience</Box>
          </Message>
        </Flex>
        <Box sx={{ mt: '30px' }}>
          <Image
            src={TwentyTwentyCover}
            size='medium'
            href='https://photos.app.goo.gl/Nz4rZYYneZ9qUZpf8'
            target='_blank'
            centered
            rounded
            style={{ boxShadow: '0 0 8px #808080', borderRadius: '10px' }}
          />
        </Box>
        <Box sx={{ mt: '25px' }}>
          <Button
            href='https://drive.google.com/drive/folders/1V7bRj38cNkHRSa-rW4jrg1IkuPukq0Ot?usp=sharing'
            target='_blank'
          >
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ mr: '8px' }}>
                <Image src={DriveLogo} size='mini' />
              </Box>
              Download full size images
            </Flex>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
