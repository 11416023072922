import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Box } from 'theme-ui';

export const UpdatedModal = () => {
  return (
    <Modal
      content={<Box>Hello this is Victor</Box>}
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  );
};
