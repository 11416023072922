import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Box } from 'theme-ui';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { Home } from './components/home';
import { InfoPage } from './components/registration';
import { Update } from './components/update';
import { Registration } from './components/registration/registration';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { MediaPageLinks } from './components/media';
import { AdminPage } from './components/admin';
import { fire, db } from './lib/firebase';

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [displayControls, setDisplayControls] = useState([]);
  if (displayControls.length > 0) {
    console.log(displayControls[0].registration);
  }
  useEffect(() => {
    (async () => {
      try {
        await fire.auth().signInAnonymously();

        fire.auth().onAuthStateChanged((user) => {
          if (user) setIsSignedIn(true);
        });

        const snapshot = await db.collection('site-controls').get();

        const res = snapshot.docs.map((displayContol) => ({
          registration: displayContol,
          ...displayContol.data(),
        }));
        setDisplayControls(res);
      } catch ({ message }) {
        console.error(message);
      }
    })();
  }, []);

  if (!isSignedIn)
    return (
      <Box>
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Box>
    );

  return (
    <Router>
      {displayControls.length === 0 ? null : (
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Segment basic>
            <Switch>
              <Route path='/home'>
                <Home />
              </Route>
              <Route path='/information'>
                <Header />
                <InfoPage />
              </Route>
              {displayControls[0].registration ? (
                <Route path='/registration'>
                  <Header />
                  <Registration />
                </Route>
              ) : null}
              <Route path='/update'>
                <Header />
                <Update />
              </Route>
              <Route path='/media'>
                <Header />
                <MediaPageLinks />
              </Route>
              <Route path='/admin'>
                <Header />
                <AdminPage />
              </Route>
              <Route path='/'>
                <Home />
              </Route>
            </Switch>
          </Segment>
          <Footer />
        </Box>
      )}
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
