import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const config = {
  apiKey: 'AIzaSyCV3nlLm12v5g2vRDQtAPiJ7F8wn6EWB54',
  authDomain: 'pnw-camp-signup.firebaseapp.com',
  projectId: 'pnw-camp-signup',
  storageBucket: 'pnw-camp-signup.appspot.com',
  messagingSenderId: '241452906457',
  appId: '1:241452906457:web:0d1d57856f76c65d1a0bc8',
};
export const fire = firebase.initializeApp(config);
export const db = fire.firestore();
export const provider = new firebase.auth.GoogleAuthProvider();
