import React from 'react';
import { Image } from 'semantic-ui-react';
import { Box } from 'theme-ui';

import PNWPic from '../../pics/PNWPic.png';

export const Header = () => {
  return (
    <Box sx={{mt: '20px', display: 'flex', justifyContent: 'center'}}>
      <Image src={PNWPic} size='small' href='/'/>
    </Box>
  );
};
