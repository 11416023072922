import { Input } from 'semantic-ui-react';

export const SearchPerson = ({ query, setQuery }) => {
  return (
    <Input
      fluid
      size='large'
      icon='search'
      value={query}
      placeholder='Type in last name'
      onChange={({ target: { value } }) => setQuery(value)}
      style={{width: '300px'}}
    />
  );
};
