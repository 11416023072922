import React, { useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { Button } from 'semantic-ui-react';

import { Listing } from './listing';
import { fire, provider } from '../../lib/firebase';

const allowedUIDs = [
  'N74Wxf50UGbWnLvWlJRm3vjpNc43', //jacob
  'yDMCigQw8zN5IZ762wGJuhGz9p93', //me
  // 'dcLYHmK9OwToldF7q1EI1e8GFwY2', //ben
  'wR1BsZinPwUYhATGKgD7zM47RJ42', //ruvim
  'RDYzPCEqu2fKuhOmnEck7TjOcj32', //sergey
  'sjch2EdwMbclUPNGa62CDUVxoCg2', //GSBCYouth
  // 'XNUy0MnAkQN8mRQX6U3Y7hwOqdn2', //Dorin
  'LoGzOC86S4YYyjahzz9rxfZvZvB2', //Elijah
  'NOPoLB4wILMTkz6jEpqoTe5IghF2', //Simon
  'i8C8NzVCKSN3pxSDmUvEpX4bL3L2', //Vit
  'K7IgTlwcxZQLZi0XEDl9kkXIgDA3', //Diana Y
  'R6NfkWptT9Tcs6yt4RIrWSMilKn2', //Lianna
  'JstDyAvs6tZ3oqAza6SPEHngwSP2', //Liza
];

export const AdminPage = () => {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    fire
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (!result || !result.user) return;

        if (!result.user.isAnonymous && allowedUIDs.includes(result.user.uid)) {
          setSignedIn(true);
        }
      });
  }, []);

  const signIn = () => {
    try {
      fire.auth().signInWithRedirect(provider);
    } catch ({ message }) {
      console.error(message);
    }
  };

  const signOut = () => {
    try {
      fire.auth().signOut();
      setSignedIn(false);
    } catch ({ message }) {
      console.error(message);
    }
  };

  if (!signedIn)
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            basic
            color='orange'
            size='huge'
            onClick={() => signIn()}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          basic
          color='orange'
          size='huge'
          onClick={() => signOut()}
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Sign Out
        </Button>
      </Box>
      <Listing />
    </Box>
  );
};
